import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './src/locales/en.json';
import esTranslation from './src/locales/es.json';
import itTranslation from "./src/locales/it.json";

const language = process.env.GATSBY_BUILD_LANG || 'en'

// Initialize i18next
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    es: {
      translation: esTranslation,
    },
    it: {
      translation: itTranslation,
    },
  },
  lng: language,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: { useSuspense: true }
});
export default i18n;

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-widgets-banner-tsx": () => import("./../../../src/pages/widgets/banner.tsx" /* webpackChunkName: "component---src-pages-widgets-banner-tsx" */),
  "component---src-pages-widgets-efx-details-tsx": () => import("./../../../src/pages/widgets/efx-details.tsx" /* webpackChunkName: "component---src-pages-widgets-efx-details-tsx" */),
  "component---src-pages-widgets-products-tsx": () => import("./../../../src/pages/widgets/products.tsx" /* webpackChunkName: "component---src-pages-widgets-products-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-product-widget-tsx": () => import("./../../../src/templates/product-widget.tsx" /* webpackChunkName: "component---src-templates-product-widget-tsx" */)
}

